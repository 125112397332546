import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

class HomepageFeatures extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		searchText:'',
  		homepageFeatures:[]
  	}

  	this.fetch()
  }

  fetch(){
    let self=this

    let searchText = (self.state.searchText||'').trim()

	  if(self.state.searchText.length<3){
      searchText=''
		  // self.setState({homepageFeatures:[]})
		  // return
	  }

    axios
      .get("/api/homepageFeatures/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({homepageFeatures:res.data})
        }
        else{

        	self.setState({homepageFeatures:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({homepageFeatures:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  toggleActive(homepageFeature){
    let self=this
    if(homepageFeature){

        homepageFeature.active = !homepageFeature.active 
        axios.put('/api/homepageFeatures/' + homepageFeature._id, {
            homepageFeature
        }).then((data) => {
         self.fetch()
        }).catch((e) => {
            console.log('Update failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });

      
    }
  }


  createFeature(){
    let self=this
    let isEJR = false
    let isWillow = false
    let isEndless = false

    if(`${localStorage.currentShop}`.toLowerCase()==='willow'){
      isWillow = true
    }
    else if(`${localStorage.currentShop}`.toLowerCase()==='endless'){
      isEndless = true
    }
    else{
      isEJR = true
    }
    axios.post('/api/homepageFeatures',{
      title:'New Feature',
      description:'[description]',
      isEJR,
      isWillow,
      isEndless
    })
    .then( (data) => {
      window.location='/homepageFeatures/'+data.id
	  })
    .catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Homepage Features
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createFeature()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="product-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}                  
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off" 
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              onChange={(e)=>{self.setState({searchText:e.target.value},self.fetch)}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body" style={{maxHeight:'74vh',overflowY:'scroll'}}>
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  {/* <th>&nbsp;</th> */}
                  <th style={{minWidth:'100px',maxWidth:'100px',width:'100px'}}>Image</th>
                  <th style={{minWidth:'100px',maxWidth:'100px',width:'100px'}}>Shop</th>
                  <th style={{minWidth:'20%',maxWidth:'20%',width:'20%'}}>Title</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                {self.state.homepageFeatures.map((c)=>{
                	return(

		                <tr style={c.active?{}:{background:"#f6d4d4"}} key={'c_'+c._id} >
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/homepageFeature/'+c._id
                                }}>
                                {c.image && c.image.length && (
                                    <img src={c.image[0]} style={{width:"100px"}} />
                                ) || null}
                            </td>
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/homepageFeature/'+c._id
                            }}>
                              {(c.isEJR && (
                              <div>EJR</div>
                              )) || null}
                              {(c.isWillow && (
                              <div>Willow</div>
                              )) || null}
                              {(c.isEndless && (
                              <div>Endless</div>
                              )) || null}
                            </td>
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/homepageFeature/'+c._id
                            }}>
                                <div>{c.title || '[MISSING]'}</div>
                            </td>
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/homepageFeature/'+c._id
                            }}>
                                {c.description && (
                                <div>{c.description}</div>
                            ) || null}
                            </td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default HomepageFeatures;
